<template>
  <div class="kecheng">
    <kind-choose-view :show-kc="false" :type="116" @isOk="isOk" />
    <div class="home-two-title">
      <div class="text">
        <slot>最近直播</slot>
      </div>
      <!--  <el-pagination
        class="redPage"
        @prev-click="handleCurrentChange"
        @next-click="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        :total="total"
        layout="prev,next"
      >
      </el-pagination> -->
    </div>
    <!-- 内容 -->
    <div v-if="list && list.length > 0" class="live">
      <div v-for="(item, index) in list" :key="index" class="item">
        <!-- 活动详情 -->
        <div style="overflow: hidden;" class="item-img">
          <img style="width: 100%;height: auto;" :src="item.img">

        </div>
        <div class="detail">
          <div class="detail-title twoEllipsis">{{ item.title }}</div>
          <div class="flex-between-c m-t-16">
            <div class="tips">
              {{ item.startTime | format("MM月dd日 HH:mm") }} 开播
            </div>
            <div class="keep-learn keep-check" @click="godetail(item)">
              立即观看
            </div>
          </div>

          <!-- 价格 -->
          <div class="detail-ter flex-between-c m-t-20">
            <div class="ter-list">
              <div class="terbox">
                <el-image class="ter-img" :src="item ? item.tImg : ''" fit="cover" />
              </div>
              <span class="ter">讲师：{{ item.terName }}</span>
            </div>
            <div class="tips">人气值 {{ item.clicks ? item.clicks : 0 }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 暂无课程 -->
    <div v-if="!list || list.length == 0" class="nothing">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无内容哦~</div>
    </div>
    <div class="flex-jc-c m-t-20">
      <el-pagination :total="total" :current-page="pageNum" :page-sizes="[8, 16, 24, 40]" :page-size="pageSize"
        :pager-count="5" layout="total, sizes, prev, pager, next, jumper" @current-change="handleCurrentChange"
        @size-change="handleSizeChange" />
    </div>
    <el-dialog title="提示" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false"
      :visible.sync="ifHave" width="400px" center>
      <span>尚未购买无法观看</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ifHave = false">取消</el-button>
        <el-button type="primary" @click="goOrder">去购买</el-button>
      </span>
    </el-dialog>
    <!-- 是否可以观看弹窗 -->
    <div v-if="showLookDetail" class="lookBg">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : "" }}</span>观看<span>{{ lookDeatil ? lookDeatil.chapterName :
            "" }}</span>,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>
<script>
import KindChooseView from '../../components/kindChooseViewzb.vue'
import Bus from '@/api/bus'
import Vue from 'vue'
import { Know, courseCheckPlay } from '@/api/know'
const know = new Know()
import { getInfo } from '@/api/cookies'
import { getLbClassCourseById } from '@/api/home'
import { OrderClient } from '@/api/orderClient'
const orderClient = new OrderClient()
export default {
  components: { KindChooseView },
  data() {
    return {
      search: {},
      list: [],
      total: 0,
      userInfo: null,
      pageSize: 8,
      pageNum: 1,
      loading: null,
      ifHave: false,
      liveId: null,
      liveshowName: '',
      live: null,
      lookDeatil: null,
      showLookDetail: false
    }
  },
  async beforeDestroy() {
    this.loading.close()
  },
  created() {
    this.userInfo = getInfo()
    // 调用轮播图导航接口方法
    this.getList()
  },
  methods: {
    // 获取列表
    async getList() {
      this.loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: '',
        background: 'rgba(0, 0, 0, 0.2)'
      })
      await know
        .getLiveshowplaybackByTypeupdata(
          {
            pageSize: this.pageSize,
            pageNum: this.pageNum,
            kind: this.search.kind ? this.search.kind : null,
            zyId: this.search.zyId ? this.search.zyId : null,
            areaIds: this.search.areaId ? this.search.areaId : null,

          }
        )
        .then((res) => {
          this.total = res.total
          this.list = res.rows
        })
      this.loading.close()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    // 筛选
    isOk(val) {
      this.search = val
      console.log(val, 'val');

      this.getList()
    },
    // 直播课详情
    zhiboke(item) {
      this.$router.push(`/zhiboclassxq?id=${item.id}&&type=2`)
    },
    async godetail(item) {
      if (!this.userInfo) {
        Vue.prototype.goLoginView(true)
        return
      }
      this.liveId = item.liveId
      this.liveshowName = item.liveshowName
      await getLbClassCourseById(item.liveId).then((res) => {
        this.ifHave = !res.data.IsPurchase
        this.live = res.data.details
      })
      if (this.ifHave) {
        return
      }
      if (item.isLive == 0) {
        this.$message.error('课程还未到开播时间')
        return
      }
      if (item.isLive == 2) {
        this.$message.error('课程直播结束')
        return
      }
      // this.$router.push({
      //   path: '/livebofang',
      //   query: {
      //     id: item.id,
      //     startTime: item.startTime,
      //   },
      // })
      courseCheckPlay({
        courseType: '1',
        userId: this.userInfo.id,
        chapterId: item.id,
        source: 1
      }).then((res) => {
        if (res.code == 0) {
          const routeUrl = this.$router.resolve({
            path: `/livebofang`,
            query: {
              id: item.id,
              startTime: item.startTime,
              chapterName: item.title
            }
          })
          if (
            /Safari/.test(navigator.userAgent) &&
            !/Chrome/.test(navigator.userAgent)
          ) {
            window.location.href = routeUrl.href
          } else {
            window.open(routeUrl.href, '_blank')
          }
        } else {
          this.lookDeatil = res.msg
          this.showLookDetail = true
        }
      })
    },
    closeLook() {
      this.showLookDetail = false
    },
    // 立即购买
    goOrder() {
      if (this.userInfo) {
        orderClient
          .addOrders(
            this.liveId,
            this.userInfo.id,
            this.live.title,
            2,
            this.live.title,
            this.live.kind
          )
          .then((res) => {
            if (res.code == 0) {
              this.$router.push({
                name: '个人中心订单页',
                params: { orderNumber: res.msg }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      } else {
        Vue.prototype.goLoginView(true)
      }
    }
  }
}
</script>
<style lang="less" scoped>
// 最近直播  内容
.live {
  // width: 66.2%;
  width: 1244px;
  margin-left: calc(50% - 610px);
  flex-wrap: wrap;
  margin-bottom: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .item {
    width: 287px;
    // height: 377px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    margin-right: 24px;
    margin-bottom: 24px;
    overflow: hidden;
    padding-bottom: 10px;

    &:hover {
      box-shadow: 0px 4px 8px 1px rgba(153, 153, 153, 0.20000000298023224);
    }

    .item-img {
      width: 287px;
      height: 161.4px;
      border-radius: 8px 8px 0px 0px;
      opacity: 1;
    }

    .detail {
      padding: 12px 16px;

      .detail-title {
        font-size: 18px;
        height: 52px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #333333;
        line-height: 26px;
      }

      .tips {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
      }
    }
  }
}

.home-two-title {
  width: 1220px;
}

.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;

    .contents {
      width: 380px;
      margin: 20px auto;

      &>span {
        color: red;
      }
    }

    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}
</style>
